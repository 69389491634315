import React, { FC, useState } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'

const LanguageMenu: FC = () => {
  const [language, setLanguage] = useState('en');

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };

  return (
    <Box style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
      <img className="language-icon" src="/img/icons/Earth.svg" alt=""/>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label="Language"
          onChange={handleChange}
        >
          <MenuItem value='en'>
            EN
          </MenuItem>
          <MenuItem value='pl'>
            PL
          </MenuItem>
          <MenuItem value='es'>
            ES
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default LanguageMenu
